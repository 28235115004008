import React, { useEffect } from "react";
import FooterBar from "../common/FooterBar";
import NavigationBar from "../common/NavigationBar";
import ServicesBody from "../common/ServicesBody";
import ReactGA from "react-ga";

const Services = () => {
  useEffect(() => {
    // Track the page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <NavigationBar />
      <ServicesBody />
      <FooterBar />
    </div>
  );
};

export default Services;
