import React, { useEffect } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicyContainer">
      <p>
        Privacy Policy Last updated: Dec 26 2023 This Privacy Policy describes
        how Qualaces Inc ("we," "us," or "our") collects, uses, and shares
        personal information when you use our website Qualaces.com (the "Site").
        By using the Site, you agree to the terms of this Privacy Policy.
        Information We Collect Personal Information ** DOT Information You
        Provide:** We collect information you voluntarily provide, such as your
        name, email address, and any other information you choose to provide
        when contacting us or using our services. DOT Automatically Collected
        Information: We may automatically collect certain information when you
        visit our Site, including your IP address, browser type, device type,
        and the pages you visit. Cookies and Similar Technologies We use cookies
        and similar technologies to collect information about your preferences
        and browsing behavior on our Site. You can control cookies through your
        browser settings and other tools. However, disabling certain cookies may
        affect the functionality of the Site. How We Use Your Information We may
        use the information we collect for the following purposes: Provide and
        maintain the Site: To deliver and maintain the functionality of the
        Site. Improve and personalize our services: To understand and analyze
        how you use our Site and to enhance your experience. Communicate with
        you: To respond to your inquiries, send newsletters, and provide updates
        about our services. Legal Compliance: To comply with applicable laws and
        regulations. Information Sharing We may share your personal information
        with third parties under the following circumstances: Service Providers:
        We may engage third-party service providers to assist with the operation
        of the Site and related services. Legal Requirements: We may disclose
        your information if required by law or in response to a legal request.
        Your Rights Depending on your location, you may have certain rights
        regarding your personal information, including the right to access,
        correct, or delete your data. Please contact us at info@qualaces.com to
        exercise these rights. Security We take reasonable measures to protect
        the confidentiality and security of your personal information. However,
        no method of transmission over the Internet or electronic storage is
        completely secure. Changes to This Privacy Policy We may update this
        Privacy Policy from time to time to reflect changes in our practices or
        for other operational, legal, or regulatory reasons. We will notify you
        of any changes by posting the updated Privacy Policy on this page.
        Contact Us If you have any questions or concerns about this Privacy
        Policy, please contact us at info@qualaces.com
      </p>
    </div>
  );
};

export default PrivacyPolicy;
