import FooterBar from "../common/FooterBar";
import NavigationBar from "../common/NavigationBar";
import PageUnderCon from "../common/PageUnderConstruction";
import ContactForm from "../common/ContactForm";
import React, { useEffect } from "react";
import ReactGA from "react-ga";

const ContactUs = () => {
  useEffect(() => {
    // Track the page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <NavigationBar />
      <ContactForm />
      <FooterBar />
    </div>
  );
};

export default ContactUs;
