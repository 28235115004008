import React from "react";
import "./Hero.css";
import Card from "./Card";

import HorizontalCard from "./HorizontalCard";
import PurpleBox from "./PurpleBox";
import FindOutMoreButton from "./FindOutMoreButton";

const Hero = () => {
  return (
    <div className="CareersBodyWrapper">
      <div className="hero">
        <div className="heroLeftContainer">
          <div className="heroText">
            <p>Unlock</p>
            <p>unparalleled</p>
            <p>efficiency in</p>
            <p>your QA</p>
            <p>process with</p>
            <p>
              <span className="qualityAces">Quality Aces</span>.
            </p>
          </div>
          <div className="hero-description">
            <p>Join us for an immersive collaboration with</p>
            <p>our top-notch experts, where you'll benefit</p>
            <p>personalized attention at a cost-effective rate,</p>
            <p> all while we uphold our commitment to quality.</p>
          </div>
          <div>
            <FindOutMoreButton />
          </div>
        </div>
        <div className="hero-image-container">
          <img src="./hero.png" alt="hero" className="hero-image"></img>
        </div>
      </div>
      <div className="hero3">
        <div>
          <h2 className="discover-text">ABOUT US</h2>
          <p className="whychooseus-text">
            Why <span className="discover-text">choose</span> us?
          </p>
          <div className="secondHeroTextContainer">
            <p>Elevate your software's performance and</p>
            <p>quality with our exceptional software and</p>
            <p>quality testing services. Qualaces</p>
            <p>designs and delivers cutting-edge QA</p>
            <p>and software testing solutions.</p>
          </div>
        </div>
        <div className="rightContainer">
          <Card
            title={"Commitment to Quality"}
            description={
              "Our testing methodologies and attention to detail guarantee that your software is thoroughly examined and validated."
            }
          />
          <Card
            title={"Our Expertise"}
            description={
              "Each member has a strong background in software testing methodologies, quality assurance, and industry best practices."
            }
          />
          <Card
            title={"Personalized Attention"}
            description={
              "Our team will work closely with you, providing clear communication, understanding your requirements, and delivering solutions."
            }
          />
          <Card
            title={"Integrity and Confidentiality"}
            description={
              "We operate in accordance with utmost moral and professional standards."
            }
          />
        </div>
      </div>
      <div className="herothird">
        <div className="leftheroContainer">
          <img src="./heroimagee.png" className="herothreeimage" />
        </div>
        <div className="rightheroContainer">
          <div>
            <h2 className="discover-text">WHAT WE DO</h2>
            <p className="whychooseus-text">Our Services</p>
          </div>
          <div className="third-hero-description">
            <p>
              As a software and quality testing services company, we provide a
              wide range of services to ensure the success of your software. Our
              offerings include performance testing to assess its scalability
              and responsiveness, compatibility testing across various platforms
              and devices, security testing to identify vulnerabilities,
              usability testing to enhance the user experience, mobile app
              testing, strategic consulting for test planning and optimization,
              and detailed test management and reporting.
            </p>
          </div>
          <FindOutMoreButton />
        </div>
      </div>
      <div className="hero4Wrapper">
        <div className="hero4">
          <p>Forerunners in</p>
          <span className="bluefonts">Software Testing</span>
        </div>
      </div>
      <div className="mainContainer">
        <div>
          <h2 className="discover-text">DISCOVER</h2>
          <p className="header-text">Main Types of Testing</p>
        </div>
        <div className="cardWrapper">
          <HorizontalCard
            title={"Smoke Testing"}
            description={
              "Smoke testing is done to ensure that the most critical functionalities of a system are working fine."
            }
          />
          <HorizontalCard
            title={"Sanity Testing"}
            description={
              "Sanity testing is a type of software testing done after a minor change in the code or functionality to ascertain that the bugs have been fixed."
            }
          />
          <HorizontalCard
            title={"Regression Testing"}
            description={
              "Regression testing is exhaustive testing that is conducted when changes or enhancements are made to the software."
            }
          />
          <HorizontalCard
            title={"Ticket Testing"}
            description={
              "Involves testing individual items or 'tickets' that are part of an issue tracking system."
            }
          />
        </div>
        <div>
          <FindOutMoreButton />
        </div>
        <PurpleBox />
      </div>
    </div>
  );
};

export default Hero;
