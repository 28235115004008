import "./Hero.css";
import React from "react";
import "./HorizontalCard.css";
const HorizontalCard = ({ title, description }) => {
  return (
    <div className="horizontalCard">
      <div className="card-left-container">
        <img src="./tiny-logo.png" className="tiny-logo"></img>
      </div>
      <div className="card-right-container">
        <p className="card-heading">{title}</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default HorizontalCard;
