import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import HomePage from "./components/views/HomePage";
import AboutUs from "./components/views/AboutUs";
import ContactUs from "./components/views/ContactUs";
import Services from "./components/views/Services";
import CareersPage from "./components/views/CareersPage";
import ReactGA from "react-ga";
import PrivacyPolicyPage from "./components/views/PrivacyPolicyPage";

// Initialize Google Analytics with your Tracking ID
ReactGA.initialize("G-PCM313WJ4W");

const App = () => {
  ReactGA.event({ category: "User", action: "Sent message" });
  return (
    <BrowserRouter>
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="services" element={<Services />} />
          <Route path="careers" element={<CareersPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
};

export default App;
