import "./CareersBody.css";
import React from "react";

const CareersBody = () => {
  return (
    <div className="CareersBodyWrapper">
      <div className="careerPageSection1">
        <p className="careerPageSection1Header">Careers</p>
        <div className="careerPageSection1TextWrapper">
          <p>
            As technology and industries evolve, staying up-to-date with the
            latest QA methodologies and tools is crucial to provide the best
            possible service.
          </p>
        </div>
      </div>
      <div className="careerPageSection2">
        <div className="careersPage1Wrapper">
          <img
            src={process.env.PUBLIC_URL + "/careerHeroImage.png"}
            // src="./careerHeroImage.png"
            className="careersPage1"
            alt="careers-page-image-1"
          />
        </div>
        <div className="careerHelpContainerHeadingWrapper">
          <p className="careerHelpContainerHeading">
            <span className="discover-text">Sorry.</span> We have no openings
            currently.
          </p>
          <p>
            However, feel free to submit your resume to careers@qualaces.com,
            and we will reach out to you when suitable opportunities arise.
            Thank you for your interest!
          </p>
        </div>
      </div>
      <div className="careerPageSection3">
        <img
          src="./CareersPage4.JPG"
          className="CareersPageImage4"
          alt="careers-page-image-2"
        ></img>
        <div className="careerPageSection3rightCotainer">
          <p className="careerHelpHead">Our Mentorship Program</p>
          <div className="careerPageSection3Text">
            <p>
              Join the Qualaces Quality Assurance Mentorship Program to unlock
              your full potential, accelerate your career, and become a
              proficient QA professional. Whether you are a recent graduate or
              looking to transition into the QA field, our program provides a
              solid foundation for success in a rapidly evolving tech landscape.
            </p>
            <p>
              Don't just follow the path; forge your own with Qualaces. Elevate
              your skills, amplify your impact, and shape the future of quality
              assurance.
            </p>
          </div>
        </div>
      </div>
      <div className="careerPageSection4">
        <div className="careerHelpContainerHeading">
          How We Can <span className="discover-text">Help</span>
        </div>
        <div className="careerHelpContainerWrapper">
          <div className="careerHelpContainer">
            <div>
              <p className="careerHelpHead">Career Building Guidance</p>
              <p>
                Benefit from personalized career advice and guidance from
                experienced mentors at Qualaces.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">Valuable Certifications</p>
              <p>
                Acquire industry-recognized certifications that add significant
                value to your professional profile.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">Hands On Experiance</p>
              <p>
                Immerse yourself in real-world projects and scenarios, gaining
                practical experience under the guidance of seasoned
                professionals.
              </p>
            </div>
          </div>
          <div className="careerHelpImageWrapper">
            <img
              src="./CareersPage3.png"
              className="careerImage2"
              alt="careers-page-image-3"
            />
          </div>
          <div className="careerHelpContainer">
            <div>
              <p className="careerHelpHead">Latest Tools and Technology</p>
              <p>
                Learn to navigate and master cutting-edge QA tools, ensuring you
                are equipped with the skills demanded by the industry.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">Fast Track Your Career</p>
              <p>
                Receive guidance on making strategic career moves and advancing
                quickly within the QA domain.
              </p>
            </div>
            <div>
              <p className="careerHelpHead">One - On - One</p>
              <p>
                Enjoy one-on-one mentorship sessions tailored to your specific
                career goals and learning needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersBody;
