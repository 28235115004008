import "./ServiceBody.css";
import PurpleBox from "./PurpleBox";

const ServicesBody = () => {
  return (
    <div className="ServicesBodyWrapper">
      <div className="ServicesBodySection1">
        <p className="discover-text centerAlignedText">WHAT WE DO</p>
        <div>
          <p className="aboutHeading">Our Software</p>
          <p className="aboutHeading">
            Testing <span className="qualityAces">Services</span>
          </p>
        </div>
        <div className="ServicesBodySection1Body">
          <p className="centerAlignedText">
            Join forces with Qualaces today to achieve a higher standard of
            software quality! In order to guarantee that your software products
            operate seamlessly and uphold the highest standards of quality and
            dependability, our firm provides a wide range of services.
          </p>
        </div>
        <h2>So lets explore!</h2>
      </div>
      <div className="ServicesBodySection2">
        <div>
          <p className="discover-text">WHAT WE PROVIDE</p>
          <p className="aboutHeading">The Main Types of Testing</p>
        </div>
        <div className="squareCardWrapper">
          <div className="servicesSquareCard">
            <img src="./card_image_1.png" className="servicesCardImage" />
            <p>Smoke Testing</p>
          </div>
          <div className="servicesSquareCard">
            <img src="./card_image_2.png" className="servicesCardImage" />
            <p>Sanity Testing</p>
          </div>
          <div className="servicesSquareCard">
            <img src="./card_image_3.png" className="servicesCardImage" />
            <p>Regression Testing</p>
          </div>
          <div className="servicesSquareCard">
            <img src="./card_image_4.png" className="servicesCardImage" />
            <p>Ticket Testing</p>
          </div>
        </div>
      </div>
      <div className="ServicesBodySection3">
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Hardware Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  Hardware testing verifies the product's full integration. It
                  is one of the last stages of the product development process.
                  The evaluation of end-to-end system standards and the
                  provision of knowledge regarding a product's quality are the
                  goals of the test.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_3.jpg" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection purplebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_7.jpg" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Automation Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  Automation testing refers to a method or procedure where the
                  computer does several tests on different software components
                  with little to no developer participation. Before it was made
                  available to users, any software that we use on a daily basis,
                  including that which runs your phone, laptop, TV, or even your
                  car, had to pass a predetermined process.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">IOT Testing </p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  IoT testing comprises doing QA tests to examine the
                  performance, security, and functionality of IoT devices. Every
                  IoT device transmits and receives data over the Internet, so
                  it is essential to confirm that they can transfer critical
                  information wirelessly before releasing them.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_2.webp" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection bluebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_9.jpeg" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Artificial Intelligence Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  The use of automated software testing methods that make use of
                  artificial intelligence (AI)—typically, machine learning—to
                  get better outcomes is known as artificial intelligence
                  testing. The notion is that those technologies can get past
                  many of the usual obstacles in automated software testing with
                  the use of artificial intelligence.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Virtual Reality Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  By fusing the virtual and actual worlds, augmented reality
                  (AR) and virtual reality (VR) applications offer a new level
                  of immersion. Augmented reality-based testing (ARBT) combines
                  augmented reality with software testing in order to enhance
                  testing by introducing a new dimension to the testers' field
                  of vision.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_4.jpg" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection purplebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_6.jpg" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading"> Mobile Testing </p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  Mobile testing is a procedure for evaluating the relevance,
                  functionality, usability, and consistency of mobile apps and
                  digital experiences (such as websites and e-commerce). Mobile
                  testing will guarantee that all of your goods and experiences
                  yield the best results, whether you're creating a responsive
                  website, redesigning an existing one, or creating the next
                  great app.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ServicesBodySection">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Chatbot Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  A chatbot is an artificial intelligence (AI) computer that can
                  start and continue a conversation with a user via messaging in
                  natural language. For instance, users can easily get what they
                  want by using the chatbot on Domino's website. Users have a
                  range of alternatives to pick from, as well as the chance to
                  engage with people.
                </p>
              </div>
            </div>
            <div className="ServicesImageContainer">
              <img src="./Services_Image_10.jpg" className="aboutUsImage" />
            </div>
          </div>
        </div>
        <div className="ServicesBodySection purplebackground">
          <div className="ServicesBodySectionWrapper">
            <div className="ServicesImageContainer">
              <img src="./Services_Image_8.jpeg" className="aboutUsImage" />
            </div>
            <div className="ServicesLeftContainer">
              <h2 className="discover-text">TYPE</h2>
              <p className="aboutHeading">Website Testing</p>
              <div className="aboutUsTextContainer">
                <p className="lineheight">
                  A chatbot is an artificial intelligence (AI) computer that can
                  start and continue a conversation with a user via messaging in
                  natural language. For instance, users can easily get what they
                  want by using the chatbot on Domino's website. Users have a
                  range of alternatives to pick from, as well as the chance to
                  engage with people.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="purpleBoxContainer">
        <PurpleBox />
      </div>
    </div>
  );
};

export default ServicesBody;
