import CareersBody from "../common/CareersBody";
import FooterBar from "../common/FooterBar";
import NavigationBar from "../common/NavigationBar";
import React, { useEffect } from "react";
import ReactGA from "react-ga";

const CareersPage = () => {
  useEffect(() => {
    // Track the page view on component mount
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <NavigationBar />
      <CareersBody />
      <FooterBar />
    </div>
  );
};

export default CareersPage;
